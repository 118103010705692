import { useDropzone } from 'react-dropzone';
import './css/layout.css';
import { ExportToBitmap } from "./functions/ExportCanvasFunctions"
import useGlobal from './store/useGlobal';
import { useCallback } from 'react';
import Konva from 'konva';
import KonvaElements from './components/KonvaElements';
import { applyHalftoneEffect } from './functions/KonvaElementsFunctions';

export default function Layout() {
    const konvaStage = useGlobal(state => state.konvaStage);
    const setCurrentUploadedFile = useGlobal(state => state.setCurrentUploadedFile);
    const setActiveElement = useGlobal(state => state.setActiveElement);
    const currentUploadedFile = useGlobal(state => state.currentUploadedFile);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const name = file.name;
                Konva.Image.fromURL(URL.createObjectURL(file), function (img) {
                    img.setAttrs({
                        name: name,
                        draggable: true,
                        offset: {
                            x: img.width() / 2,
                            y: img.height() / 2,
                        },
                    });
                    img.cache();
                    setCurrentUploadedFile(img);
                });
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    function startExport() {
        setActiveElement(null);
        ExportToBitmap(konvaStage);
    }

    return <>
        <div id="layout">
            {/* <button className="button" onClick={startDotMatrixEffect}>convert into dot-matrix</button> */}
            <button className="button" onClick={startExport}>export to bitmap</button>
            <div className='dropzone' {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <KonvaElements />
        </div >
    </>
}