import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { CanvasTexture, LinearFilter, LinearMipMapLinearFilter } from "three";
import useGlobal from "../store/useGlobal";

export default function ObjectCanvasTexture(props) {
    const primitiveRef = useRef();
    const canvasTextureUpdater = useGlobal(state => state.canvasTextureUpdater);
    const rootDomain = useGlobal(state => state.rootDomain);
    const fps = useGlobal(state => state.fps);
    const canvasTextureRef = useRef(CanvasTexture);
    const [activeObject, setActiveObject] = useState(useGLTF(`${rootDomain}/assets/objects/sachet.glb`));//default object

    //initial useEffect
    useEffect(() => {
        activeObject.materials["design"].map = canvasTextureRef.current;
    }, [])

    //onLoad object useEffect
    useEffect(() => {
        if (props.object) {
            setActiveObject(useGLTF(`${rootDomain + props.object}`));
            activeObject.materials["design"].map = canvasTextureRef.current;
        }
    }, [props.object])

    //on canvasTextureUpdater change
    useEffect(() => {
        updateCanvasTexture()
    }, [canvasTextureUpdater])

    useFrame(() => {
        setTimeout(() => {
            updateCanvasTexture()
        }, 1000 / fps);
    })

    //Update canvasTexture
    function updateCanvasTexture() {
        canvasTextureRef.current.needsUpdate = true;
    }

    return <>
        <primitive visible={true} ref={primitiveRef} object={activeObject.scene} position={[props.position.x, props.position.y, props.position.z]} rotation={[props.rotation.x, props.rotation.y, props.rotation.z]} />
        <meshStandardMaterial attach="material" name="Design" transparent />
        <canvasTexture ref={canvasTextureRef} attach="map" image={props.konvaCanvas.current.canvas._canvas} magFilter={LinearFilter} minFilter={LinearMipMapLinearFilter} anisotropy={10} flipY={false} />
    </>
}