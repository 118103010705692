import App from './App'
import Layout from './Layout'
import './css/style.css'
import ReactDOM from 'react-dom/client'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <App />
        <Layout />
    </>
)