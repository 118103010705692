import { useEffect } from "react";
import useGlobal from "../store/useGlobal";

export default function KonvaElements(props) {
    const activeElement = useGlobal(state => state.activeElement);
    const setActiveElement = useGlobal(state => state.setActiveElement);
    const addedKonvaElements = useGlobal(state => state.addedKonvaElements);
    const setAddedKonvaElements = useGlobal(state => state.setAddedKonvaElements);
    const elementValuesUpdater = useGlobal(state => state.elementValuesUpdater);

    useEffect(() => {
        // console.group(activeElement)
    }, [activeElement])

    useEffect(() => {

    }, [addedKonvaElements])

    useEffect(() => {
    }, [elementValuesUpdater])

    document.addEventListener('keydown', function (event) {
        if (event.keyCode === 46) {
            if (activeElement) {
                deleteElement(activeElement);
            }
        }
    })

    function deleteElement(konvaElement) {
        if (activeElement) {
            setAddedKonvaElements(addedKonvaElements.filter((element) => element !== activeElement));
            activeElement.remove();
        } else {
            setAddedKonvaElements(addedKonvaElements.filter((element) => element !== konvaElement));
            konvaElement.remove();
        }
        setActiveElement(null);
    }

    return <>
        {activeElement && <div id="activeElementControls">
            <div className="elementAttributeDiv">
                <label>{activeElement.attrs.name}</label>
            </div>
            {activeElement.attrs.draggable && <div className="elementAttributeDiv">
                <label>Draggable: </label>
                <label>{activeElement.attrs.draggable.toString()}</label>
            </div>}
            {activeElement.attrs.scaleX && <div className="elementAttributeDiv">
                <label>ScaleX: </label>
                <label>{activeElement.attrs.scaleX.toFixed(2)}</label>
            </div>}
            {activeElement.attrs.scaleY && <div className="elementAttributeDiv">
                <label>ScaleY: </label>
                <label>{activeElement.attrs.scaleY.toFixed(2)}</label>
            </div >}
            {activeElement.attrs.x && <div className="elementAttributeDiv">
                <label>posX: </label>
                <label>{activeElement.attrs.x.toFixed(2)}</label>
            </div >}
            {activeElement.attrs.y && <div className="elementAttributeDiv">
                <label>posY: </label>
                <label>{activeElement.attrs.y.toFixed(2)}</label>
            </div >}
            {activeElement.attrs.fill && <div className="elementAttributeDiv">
                <label>{activeElement.attrs.fill}</label>
            </div >}
        </div >
        }
    </>
}