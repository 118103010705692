import { Backdrop, Environment, MeshReflectorMaterial, OrbitControls, Stage } from "@react-three/drei";
import { Layer, Stage as KonvaStage, Circle, Rect, Group, Transformer } from "react-konva";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useState } from "react";
import { LinearEncoding, NoToneMapping, SRGBColorSpace } from "three";
import ObjectCanvasTexture from "./components/ObjectCanvasTexture";
import Draggable from "react-draggable";
import useGlobal from "./store/useGlobal";
import { addUploadedFileToCanvas } from "./functions/KonvaElementsFunctions";

//camerasetting
const cameraSettings = {
    fov: 45,
    near: 0.1,
    far: 200,
    position: [10, 10, 20],
    zoom: .75,
}

export default function App() {
    const konvaCanvas = useRef();
    const konvaStageRef = useRef();
    const imageGroupRef = useRef();
    const transformerRef = useRef();
    const backGroundRectRef = useRef(); //background rect for sachet object
    const setKonvaStage = useGlobal(state => state.setKonvaStage);
    const konvaCanvasWidth = useGlobal(state => state.konvaCanvasWidth);
    const konvaCanvasHeight = useGlobal(state => state.konvaCanvasHeight);
    const addedKonvaElements = useGlobal(state => state.addedKonvaElements);
    const setAddedKonvaElements = useGlobal(state => state.setAddedKonvaElements);
    const canvasTextureUpdater = useGlobal(state => state.canvasTextureUpdater);
    const setCanvasTextureUpdater = useGlobal(state => state.setCanvasTextureUpdater);
    const currentUploadedFile = useGlobal(state => state.currentUploadedFile);
    const setCurrentUploadedFile = useGlobal(state => state.setCurrentUploadedFile);
    const elementValuesUpdater = useGlobal(state => state.elementValuesUpdater);
    const setElementValuesUpdater = useGlobal(state => state.setElementValuesUpdater);
    const fps = useGlobal(state => state.fps);
    const [isCanvasDragged, setIsCanvasDragged] = useState(true);
    const activeElement = useGlobal(state => state.activeElement);
    const setActiveElement = useGlobal(state => state.setActiveElement);

    //initial useEffect
    useEffect(() => {
        setCanvasesSizes();
    }, [konvaCanvasWidth, konvaCanvasHeight])

    //on konvaCanvas change
    useEffect(() => {
        if (konvaStageRef.current) {
            setKonvaStage(konvaStageRef.current);
        }
    }, [konvaStageRef.current])

    useEffect(() => {
        if (konvaCanvas.current.children) {
            for (const child of konvaCanvas.current.children) {
                child.cache();
            }
        }
    }, [konvaCanvas.current])

    useEffect(() => {
        if (transformerRef.current) {
            for (const child of transformerRef.current.children) {
                child.cache();
            }
        }
    }, [transformerRef.current])

    useEffect(() => {
        if (activeElement) {
            transformerRef.current.nodes([activeElement]);
        } else {
            transformerRef.current.nodes([])
        }
    }, [activeElement])

    function setCanvasesSizes() {
        konvaStageRef.current.attrs.container.style.scale = (window.innerHeight - 50) / konvaCanvasHeight;
    }

    //handles
    function handleScrollOnCanvas(e) {
        setTimeout(() => {
            if (e.evt.deltaY > 0) {
                if (konvaStageRef.current.attrs.container.style.scale === "") {
                    konvaStageRef.current.attrs.container.style.scale = 1;
                }
                if (konvaStageRef.current.attrs.container.style.scale > 0.1) {
                    konvaStageRef.current.attrs.container.style.scale = parseFloat(konvaStageRef.current.attrs.container.style.scale) - 0.05;
                }
            } else if (e.evt.deltaY < 0) {
                konvaStageRef.current.attrs.container.style.scale = parseFloat(konvaStageRef.current.attrs.container.style.scale) + 0.05;
            }
        }, 1000 / fps);
    }

    function checkIfClickedOnElement(e) {
        if (e.target.attrs.id === "konva" || e.target.attrs.className === "nonDraggable") {
            if (transformerRef.current.nodes().length > 0) {
                setActiveElement(null);
            }
            if (!isCanvasDragged) {
                setIsCanvasDragged(true);
            }
        } else {
            if (activeElement !== e.target && !e.target.attrs.name.includes("anchor")) {
                setActiveElement(e.target);
            }
            if (isCanvasDragged) {
                setIsCanvasDragged(false);
            }
        }
    }
    function handleTransform() {
        setElementValuesUpdater(elementValuesUpdater + 1);
    }

    function handleClickOnCanvas(e) {
        if (currentUploadedFile) {
            addUploadedFileToCanvas(imageGroupRef.current, currentUploadedFile, e.target.getStage().getPointerPosition())
            setAddedKonvaElements(imageGroupRef.current.children);
            setCurrentUploadedFile(null);
        }
    }

    function updateCanvasTexture() {
        // setTimeout(() => {
        //     setCanvasTextureUpdater(canvasTextureUpdater + 1);
        // }, 1000 / fps);
    }

    return <>
        <Draggable onStart={() => (isCanvasDragged)} ><div style={{ width: "100%", height: "100%", top: 0, left: 0, position: "fixed" }}>
            <KonvaStage id="konva" ref={konvaStageRef} width={konvaCanvasWidth} height={konvaCanvasHeight} style={{ left: window.innerHeight / 2, top: 30 }} onWheel={(e) => (handleScrollOnCanvas(e))} onMouseDown={(e) => (checkIfClickedOnElement(e))} onClick={(e) => (handleClickOnCanvas(e))} onDragMove={updateCanvasTexture}>
                <Layer ref={konvaCanvas}>
                    <Rect className="nonDraggable" ref={backGroundRectRef} width={konvaCanvasWidth} height={konvaCanvasHeight} fill="white" position={[0, 0]} />
                    <Group ref={imageGroupRef} name="elementsGroup" />
                    {/* <Rect width={500} height={500} fill="red" name="testRect" draggable />
                    <Circle x={100} y={100} radius={50} name="testCircle" fill="blue" draggable /> */}
                    <Transformer rotateAnchorOffset={100} anchorSize={konvaCanvasWidth / 50} anchorStroke="black" anchorStrokeWidth={3} borderEnabled={false} ref={transformerRef} onDragMove={handleTransform} onTransform={handleTransform} />
                </Layer>
            </KonvaStage>
        </div></Draggable>
        <Canvas frameloop="always" id="configurator" dpr={[1, 2]} camera={cameraSettings} gl={{ antialias: true, pixelRatio: .1, outputColorSpace: SRGBColorSpace }}>
            <color attach="background" args={['#efefef']} />
            <fog attach="fog" args={['#efefef', 10, 50]} />
            <Suspense fallback={null}>
                <Stage contactShadow={true} environment="city" intensity={1}>
                    <ObjectCanvasTexture konvaCanvas={konvaCanvas} rotation={{ x: -.3, y: 0, z: 0 }} position={{ x: 0, y: 1, z: 0 }} object="/assets/objects/sachet.glb" />
                </Stage>
                <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -5, 0]}>
                    <planeGeometry args={[170, 170]} />
                    <MeshReflectorMaterial
                        blur={[300, 100]}
                        resolution={1024}
                        mixBlur={1}
                        mixStrength={1}
                        roughness={1}
                        depthScale={1.2}
                        minDepthThreshold={0.4}
                        maxDepthThreshold={1.4}
                        color="#efefef"
                        metalness={.5}
                    />
                </mesh>
                <OrbitControls />
            </Suspense>
        </Canvas>
    </>
}