import { create } from "zustand";

const useGlobal = create((set) => ({
    //rootdomain
    rootDomain: null,
    fetchRootDomain: async () => {
        if (window.location.href.includes("flluxdemo")) {
            set({ rootDomain: "https://ranobo.fluxdemo.be/" });
        } else {
            set({ rootDomain: "." });
        }
    },

    // Config
    config: null,
    fetchConfig: async () => {
        let rtDomain = ".";
        if (window.location.href.includes("flluxdemo")) {
            rtDomain = "https://ranobo.fluxdemo.be/";
        }
        const response = await fetch(rtDomain + '/data/config.json');
        const json = await response.json();
        set({ config: json });
        setDefaultState();
    },
    //konvaCanvas resolution
    konvaCanvasWidth: 500,
    setKonvaCanvasWidth: (width) => set({ konvaCanvasWidth: width }),

    konvaCanvasHeight: 500,
    setKonvaCanvasHeight: (height) => set({ konvaCanvasHeight: height }),

    //framesPerSecond
    fps: 30,
    setFps: (fps) => set({ fps: fps }),

    //konva stage
    konvaStage: null,
    setKonvaStage: (stage) => set({ konvaStage: stage }),

    //added konva elements
    addedKonvaElements: [],
    setAddedKonvaElements: (elements) => set({ addedKonvaElements: elements }),

    //active element
    activeElement: null,
    setActiveElement: (element) => set({ activeElement: element }),

    //uploaded files
    currentUploadedFile: null,
    setCurrentUploadedFile: (file) => set({ currentUploadedFile: file }),

    //HACKY STUFF
    //update canvasTexture
    canvasTextureUpdater: 0,
    setCanvasTextureUpdater: (value) => set({ canvasTextureUpdater: value }),

    //update elementvalues
    elementValuesUpdater: 0,
    setElementValuesUpdater: (value) => set({ elementValuesUpdater: value }),
}));
export default useGlobal;
//init rootdomain
useGlobal.getState().fetchRootDomain();
//fetch config file
useGlobal.getState().fetchConfig();

//set defaults from config file
function setDefaultState() {
    const config = useGlobal.getState().config;
    const setKonvaCanvasWidth = useGlobal.getState().setKonvaCanvasWidth;
    const setKonvaCanvasHeight = useGlobal.getState().setKonvaCanvasHeight;
    const setFps = useGlobal.getState().setFps;
    setKonvaCanvasHeight(config.settings.canvasHeight);
    setKonvaCanvasWidth(config.settings.canvasWidth);
    setFps(config.settings.framesPerSecond);
}